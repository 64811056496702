import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdatePhoneMutation } from '@store/api/user.api';

import { isApiError, translateApiError } from '@shared/utils/errors';

export const useUpdatePhone = () => {
  const { t } = useTranslation();

  const [mutation, { isLoading }] = useUpdatePhoneMutation();
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const updatePhone = async (requestId: string, code: string) => {
    try {
      if (!requestId) {
        return;
      }

      setApiErrors([]);

      return await mutation({ requestId, code }).unwrap();
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }
    }
  };

  return [updatePhone, { errors: apiErrors, isLoading }] as const;
};
