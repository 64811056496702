import React from 'react';

import { UpdatePhoneTitle } from '@widgets/profile/update-phone/title';
import { UpdatePhoneForm } from '@widgets/profile/update-phone/update-phone-form';

const UpdatePhonePage = () => {
  return (
    <div className="px-4 pt-6 pb-12 lg:pt-12">
      <UpdatePhoneTitle />
      <UpdatePhoneForm />
    </div>
  );
};

export default UpdatePhonePage;
