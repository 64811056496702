import React from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';

export const UpdatePhoneTitle = () => {
  const { t } = useTranslation();

  const icon = useGetIconOptimized('camera-handshake');

  return (
    <div className="flex flex-col items-center md:flex-col md:justify-center mb-2">
      <GatsbyImage image={getImage(icon)!} alt="icon" className="w-[48px] h-auto md:w-[48px]" />
      <div className="ml-4 md:ml-0 md:mt-[18px]">
        <h1 className="text-xl text-center md:text-2xl">{t('enter-phone-number')}</h1>
      </div>
    </div>
  );
};
