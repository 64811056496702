import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateUpdatePhoneRequestMutation } from '@store/api/user.api';
import { isApiError, translateApiError } from '@shared/utils/errors';

export const useCreateUpdatePhoneRequest = () => {
  const { t } = useTranslation();

  const [mutation, { isLoading }] = useCreateUpdatePhoneRequestMutation();
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const createPhoneUpdateRequest = async (phoneNumber: string) => {
    try {
      setApiErrors([]);

      const { requestId } = await mutation({ phoneNumber }).unwrap();

      return { requestId };
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }

      return null;
    }
  };

  return [createPhoneUpdateRequest, { isLoading, errors: apiErrors }] as const;
};
