import React, { useState } from 'react';

import { SpecifyPhoneNumberForm } from '@widgets/auth/SpecifyPhoneNumberForm';
import { VerifyPhoneNumber } from '@widgets/merge/verify-phone-number-form';

import { useNavigate } from '@hooks/useNavigate';

import { Routes } from '@shared/enums';

import { useCreateUpdatePhoneRequest } from './hooks/useCreateUpdatePhoneRequest';
import { useUpdatePhone } from './hooks/useUpdatePhone';

export const UpdatePhoneForm = () => {
  const navigate = useNavigate();

  const [requestId, setRequestId] = useState<string | null>(null);

  const [createUpdatePhoneRequest, { isLoading: isUpdatePhoneRequestLoading, errors: updatePhoneRequestErrors }] =
    useCreateUpdatePhoneRequest();
  const [updatePhone, { isLoading: isUpdatePhoneLoading, errors: updatePhoneErrors }] = useUpdatePhone();

  const isLoading = isUpdatePhoneRequestLoading || isUpdatePhoneLoading;
  const apiErrors = updatePhoneRequestErrors.concat(updatePhoneErrors);

  const onPhoneNumberSubmit = async (phoneNumber: string) => {
    const result = await createUpdatePhoneRequest(phoneNumber);

    if (result) {
      setRequestId(result.requestId);
    }
  };

  const onVerificationCodeSubmit = async (code: string) => {
    if (!requestId) {
      return;
    }

    const result = await updatePhone(requestId, code);

    if (result?.success) {
      navigate(Routes.Profile);
    }
  };

  if (requestId) {
    return (
      <VerifyPhoneNumber
        disabled={isLoading}
        errors={apiErrors}
        onSubmit={onVerificationCodeSubmit}
        onBack={() => setRequestId(null)}
      />
    );
  }

  return (
    <SpecifyPhoneNumberForm
      disabled={isLoading}
      errors={apiErrors}
      onSubmit={onPhoneNumberSubmit}
      onBack={() => navigate(Routes.Profile)}
    />
  );
};
